<template>
<div id="AB">
  <div class="section">
    <div class="AB-visi">
      <div class="AB-inner" id="visi-inner">
        <div class="AB-visi-left" data-aos="fade-right" data-aos-duration="800">
          <img src="../../assets/logobw.png" alt="" />
          <h4><I><B>Integrated <span class="green">Agri</span><span class="red">Tech</span> Company</B></I></h4>
        </div>
        <div class="AB-visi-right" data-aos="fade-left" data-aos-delay="300" data-aos-duration="800">
          <p>
            <span class="green"><b>Kharisma Agri Inovasi</b></span> adalah perusahaan rintisan yang bergerak
            dalam pengembangan dan penyediaan <b><span class="green">Agri</span><span class="red">Tech</span></b> 4.0, 
            serta mengelola data untuk meningkatkan 
            kesejahteraan petani.
          </p>
          <h2><span class="green">VISI</span></h2>
          <p>
            Penyedia <b><span class="green">Agri</span><span class="red">Tech</span></b> terdepan di Asia
          </p>
          <h2><span class="green">MISI</span></h2>
          <p>
            Meningkatkan kesejahteraan para petani dan
            pengguna <b><span class="green">Agri</span><span class="red">Tech</span></b>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="AB-Prod">
      <div class="AB-inner">
        <h1 class="pr-txt-title aos" data-aos="fade-down" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
          LAYANAN KAMI
        </h1>
        <div class="AB-Layanan-det">
          <div class="AB-Layanan-1">
            <img class="aos" data-aos="fade-right" data-aos-delay="600" data-aos-duration="800" data-aos-once="true" src="../../assets/OutdoorFarm.png" alt="" />
          </div>
          <div class="AB-Layanan-2">
            <img class="aos" data-aos="fade-right" data-aos-delay="600" data-aos-duration="800" data-aos-once="true" src="../../assets/IndoorFarm.png" alt="" />
          </div>
          <div class="AB-layanan-txt1 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1>Outdoor Farming</h1>
            <!-- <h2>CROP HERO OUTDOOR</h2> -->
            <p>
              <ol>
                <li>Instalasi Irigasi : curah/sprinkler</li>
                <li>Sistem Monitoring<br>Sensor Outdoor (CH-O) disertai aplikasi</li>
                <li>Sistem Kontrol<br>Menggunakan CH-C disertai aplikasi Crop-Hero</li>
              </ol>
            </p>
          </div>
          <!-- <div class="AB-layanan-txt2 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1>Indoor Farming</h1>
            <p>
              <ol>
                <li>Instalasi Greenhouse</li>
                <li>Sistem Monitoring<br>Sensor Indoor (CH-I), Kamera monitoring, serta aplikasi</li>
                <li>Sistem Kontrol<br>Menggunakan CH-C dan aplikasi Crop-Hero</li>
              </ol>
            </p>
          </div> -->
          <div class="AB-layanan-txt2 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1>Indoor Farming</h1>
            <p>
              <ol>
                <li>Instalasi Greenhouse</li>
                <li>Sistem Monitoring<br>Sensor Indoor (CH-I), Kamera monitoring, serta aplikasi</li>
                <li>Sistem Kontrol<br>Menggunakan CH-C dan aplikasi Crop-Hero</li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="Brand">
      <div class="AB-inner">
        <h1 class="pr-txt-title aos" data-aos="fade-down" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
          <span class="green">BRAND KAMI</span>
        </h1>
        <img class="pr-img-title aos" data-aos="zoom-in" data-aos-delay="400" data-aos-duration="600" data-aos-once="true" src="../../assets/croplg.png" alt="" />
        <h5>Your Smart Farming Assistant</h5>
        <br><br><br>
        <p>
          Crop-Hero (CH) merupakan salah satu brand
          dari PT Kharisma Agri Inovasi (KAI). Saat ini,
          KAI menyediakan AgriTech untuk indoor
          dan outdoor farming. Produk KAI terdiri dari
          CH-Monitoring dan CH-Controlling yang
          terintegrasi dengan mobile apps.
          CH-Monitoring merupakan perangkat yang
          mampu mencatat 6 parameter data
          lingkungan, yaitu suhu udara, suhu air,
          kelembaban udara, intensitas cahaya, pH
          (keasaman larutan) dan kepekatan nutrisi
          (electrical conductivity).
          CH-Controlling merupakan perangkat pintar
          yang membantu untuk mengatur kondisi
          lingkungan sesuai dengan kebutuhan
          tanaman.
        </p>
      </div>
    </div>
    <div class="Nilai">
        <h6>
          4
        </h6>
        <h5>
          Proposisi Nilai
        </h5>
        <img class="aos" data-aos="flip-left" data-aos-delay="900" data-aos-duration="1000"  data-aos-once="true" src="../../assets/proposisiNilai1.png" alt="" />
        <h3>Meningkatkan hasil panen dan mengurangi resiko gagal panen</h3>
        <img class="aos" data-aos="flip-left" data-aos-delay="900" data-aos-duration="1000"  data-aos-once="true" src="../../assets/proposisiNilai2.png" alt="" />
        <h3>Mengurangi biaya produksi</h3>
        <img class="aos" data-aos="flip-left" data-aos-delay="900" data-aos-duration="1000"  data-aos-once="true" src="../../assets/proposisiNilai3.png" alt="" />
        <h3>Meningkatkan produktivitas lahan</h3>
        <img class="aos" data-aos="flip-left" data-aos-delay="900" data-aos-duration="1000"  data-aos-once="true" src="../../assets/proposisiNilai4.png" alt="" />
        <h3>Meningkatkan keuntungan</h3>
        <br><br><br><br>
  </div>
  </div>

  <div class="section">
      <div class="Milestone">
        <div class="AB-inner">
        <h1 class="pr-txt-title aos" data-aos="fade-down" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
          <span class="green">MILESTONE</span>
        </h1>
        <!-- <img class="pr-img-title aos" data-aos="zoom-in" data-aos-delay="400" data-aos-duration="600" data-aos-once="true" src="../../assets/croplg.png" alt="" /> -->
        <div class="MileYear">
        <h7>2018</h7>
        </div>
        <div class="MileText">
        <p>
          <ul>
            <li>
              Pembentukan tim "Kharisma Crop" dan brand produk "Crop
              Hero". Produk yg dikembangkan adalah Crop Hero Offline
              (Penyimpanan data di SD Card) dengan 4 sensor
              (kelembaban udara, suhu udara, suhu air dan intensitas
              cahaya).
            </li>
            <li>
              Penerima hibah KBMI 2018 dan Juara 2 Lomba Pendamping
              Pimnas bidang Kewirausahaan
            </li>
            <li>
              Pengembangan Crop Hero Online dengan database lokal
            </li>
            <li>
              Pengembangan Crop Hero dengan database cloud serta
              penambahan sensor pH air dan EC serta Juara 3 KBMI Bidang
              Teknologi
            </li>
          </ul>
        </p>
        </div>
        <div class="MileYear">
        <h7>2019</h7>
        </div>
        <div class="MileText">
        <p>
          <ul>
            <li>
              Pengembangan awal aplikasi Crop-Hero
            </li>
            <li>
              Pengembangan Crop Hero Outdoor
            </li>
            <li>
              Juara 1 Agribusiness Startup Competition Banyuwangi,
              Indonesia
            </li>
            <li>
              Juara 1 Agro-Maritim Partai Nasdem
            </li>
             <li>
              Juara 1 Akselerasi Startup Mahasiswa Indonesia
            </li>
             <li>
              Hibah Wirausaha Muda Pemula Kemenpora
            </li>
             <li>
              Hibah Program Mahasiswa Wirausaha IPB
            </li>
          </ul>
        </p>
        </div>
        <div class="MileYear">
        <h7>2020</h7>
        </div>
        <div class="MileText">
        <p>
          <ul>
            <li>
              Pembentukan PT Kharisma Agri Inovasi
            </li>
            <li>
              Instalasi irigasi sprinkler lahan jahe dengan 2 pesantren dan
              implementasi teknologi dalam greenhouse dengan 9
              pesantren
            </li>
          </ul>
        </p>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="AB-Prod">
      <div class="AB-inner">
        <h1 class="pr-txt-title aos" data-aos="fade-down" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
          PRODUK KAMI
        </h1>
        <!-- <img class="pr-img-title aos" data-aos="zoom-in" data-aos-delay="400" data-aos-duration="600" data-aos-once="true" src="../../assets/croplg.png" alt="" /> -->
        <div class="AB-Prod-det">
          <div class="AB-Prod-2">
            <div class="prod-pr-a">
              <img class="pr-a aos" data-aos="fade-right" data-aos-delay="500" data-aos-duration="800" data-aos-once="true" src="../../assets/img1a.png" alt="" />
            </div>
            <img class="aos" data-aos="fade-right" data-aos-delay="600" data-aos-duration="800" data-aos-once="true" src="../../assets/img1ab.png" alt="" />
          </div>
          <div class="AB-Prod-1">
            <div class="prod-pr-a">
              <img class="pr-a aos" data-aos="fade-right" data-aos-delay="500" data-aos-duration="800" data-aos-once="true" src="../../assets/img3a.png" alt="" />
            </div>
            <img class="aos" data-aos="fade-right" data-aos-delay="600" data-aos-duration="800" data-aos-once="true" src="../../assets/img3ab.png" alt="" />
          </div>
          <div class="AB-Prod-3">
            <div class="prod-pr-a">
              <img class="pr-a aos" data-aos="fade-right" data-aos-delay="500" data-aos-duration="800" data-aos-once="true" src="../../assets/img2a.png" alt="" />
            </div>
            <img class="aos" data-aos="fade-right" data-aos-delay="600" data-aos-duration="800" data-aos-once="true" src="../../assets/img2ab.png" alt="" />
          </div>
          <div class="AB-prod-txt1 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1><b>CH-O</b></h1>
            <h2><b>CROP HERO OUTDOOR</b></h2>
            <p>
              Perangkat monitoring untuk pertanian outdoor atau pada lahan terbuka. Mampu mencatat 5 parameter data lingkungan dalam 1 perangkat, yaitu suhu udara, suhu tanah, kelembaban udara, intensitas cahaya, dan pH tanah. Dilengkapi pula oleh
              panel surya sehingga perangkat ini tidak perlu disambungkan dengan sumber listrik
            </p>
          </div>
          <div class="AB-prod-txt2 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1><b>CH-I</b></h1>
            <h2><b>CROP HERO INDOOR</b></h2>
            <p>
              Perangkat monitoring untuk budidaya dalam greenhouse. Mampu mencatat 6 parameter data lingkungan dalam 1 perangkat, yaitu suhu udara, suhu air, kelembaban udara, intensitas cahaya, pH (keasaman larutan) dan kepekatan nutrisi (electrical
              conductivity). Setiap parameter memiliki threshold minimum dan maksimum sesuai data tanaman yang di input user dan kemudian akan mengirim sinyal ke unit kontrol apabila kondisi lingkungan tidak berada dalam range kondisi optimum tumbuh
              kembang tanaman.
            </p>
          </div>
          <div class="AB-prod-txt3 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1><b>CH-C</b></h1>
            <h2><b>CROP HERO CONTROL</b></h2>
            <p>
              Menerima data kondisi lingkungan dari unit monitoring dan memberikan perintah on/off untuk kipas, pompa, cooling pad dll agar kondisi lingkungan terkontrol dan berada dalam range kondisi optimum tumbuh kembang tanaman.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="AB-info">
      <div class="AB-inner">
        <div class="AB-info-1">
          <img class="aos" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000" data-aos-once="true" src="../../assets/img1b.png" alt="" />
          <h2 class="aos" data-aos="fade" data-aos-delay="650" data-aos-duration="1000" data-aos-once="true">
            Pengendalian Agrikultur
          </h2>
        </div>
        <div class="AB-info-2">
          <img class="aos" data-aos="flip-left" data-aos-delay="600" data-aos-duration="1000"  data-aos-once="true" src="../../assets/img2b.png" alt="" />
          <h2 class="aos" data-aos="fade" data-aos-delay="750" data-aos-duration="1000" data-aos-once="true">
            Realtime Monitoring
          </h2>
        </div>
        <div class="AB-info-3">
          <img class="aos" data-aos="flip-left" data-aos-delay="700" data-aos-duration="1000" data-aos-once="true" src="../../assets/img3b.png" alt="" />
          <h2 class="aos" data-aos="fade" data-aos-delay="850" data-aos-duration="1000" data-aos-once="true">
            Kemudahan Akses
          </h2>
        </div>
        <div class="AB-info-4">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/img4b.png" alt="" />
          <h2 class="aos" data-aos="fade" data-aos-delay="950" data-aos-duration="1000" data-aos-once="true">
            Ekonomis
          </h2>
        </div>
      </div>
    </div>


  <div class="section">
    <div class="AB-App">
      <div id="App-inner" class="AB-inner">
        <div class="AB-App-up">
          <h1 class="aos" data-aos="fade-right" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
            APLIKASI KAMI
          </h1>
          <div class="AB-App-up-aval aos" data-aos="fade" data-aos-delay="1600" data-aos-duration="600" data-aos-once="true">
            <h3>Tersedia di </h3>
            <img v-on:click="playStore()" src="../../assets/gp.png" alt="" />
          </div>
        </div>
        <img class="AB-App-down aos" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/phone.png" alt="" />
      </div>
    </div>
  </div>

  <div class="section">
    <div class="AB-Prod">
      <div class="AB-inner">
        <h1 class="pr-txt-title aos" data-aos="fade-down" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
          Implementasi Produk & Kerjasama Perusahaan
        </h1>
        <!-- <img class="pr-img-title aos" data-aos="zoom-in" data-aos-delay="400" data-aos-duration="600" data-aos-once="true" src="../../assets/croplg.png" alt="" /> -->
        <div class="AB-Prod-det">
          <div class="AB-Prod-2">
            <div class="prod-pr-a">
              <!-- <img class="pr-a aos" data-aos="fade-right" data-aos-delay="500" data-aos-duration="800" data-aos-once="true" src="../../assets/img1a.png" alt="" /> -->
            </div>
            <img class="aos" data-aos="fade-right" data-aos-delay="600" data-aos-duration="800" data-aos-once="true" src="../../assets/Implementasi1.jpg" alt="" />
          </div>
          <div class="AB-Prod-1">
            <div class="prod-pr-a">
              <!-- <img class="pr-a aos" data-aos="fade-right" data-aos-delay="500" data-aos-duration="800" data-aos-once="true" src="../../assets/img3a.png" alt="" /> -->
            </div>
            <img class="aos" data-aos="fade-right" data-aos-delay="600" data-aos-duration="800" data-aos-once="true" src="../../assets/Implementasi2.jpg" alt="" />
          </div>
          <div class="AB-Prod-3">
            <div class="prod-pr-a">
              <!-- <img class="pr-a aos" data-aos="fade-right" data-aos-delay="500" data-aos-duration="800" data-aos-once="true" src="../../assets/img2a.png" alt="" /> -->
            </div>
            <img class="aos" data-aos="fade-right" data-aos-delay="600" data-aos-duration="800" data-aos-once="true" src="../../assets/Implementasi3.jpg" alt="" />
          </div>
          <div class="AB-prod-txt1 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1>Sensor Indoor</h1>
            <p>
              <b>Lokasi:</b><br>
              PT Momenta Agrikultura
              Lembang, Kab Bandung Utara
            </p>
            <p>
              <b>Durasi:</b><br>
              Maret 2018 - 2020
            </p>
            <p>
              <b>Hasil:</b><br>
              Meningkatkan produktivitas pekerja (50-60% dari jam kerja)
            </p>
          </div>
          <div class="AB-prod-txt2 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1>Sensor Indoor</h1>
            <p>
              <b>Lokasi:</b><br>
              PT BISI International Kediri,
              Jawa Timur
            </p>
            <p>
              <b>Durasi:</b><br>
              September 2018 – 2020
            </p>
            <p>
              <b>Hasil:</b><br>
              Meningkatkan produktivitas pekerja (50-60% dari jam kerja)
            </p>
          </div>
          <div class="AB-prod-txt3 aos" data-aos="fade" data-aos-delay="700" data-aos-duration="600" data-aos-once="true">
            <h1>Sensor Indoor</h1>
            <p>
              <b>Lokasi:</b><br>
              Plant Factory IPB, Leuwikopo,
              Dramaga
            </p>
            <p>
              <b>Durasi:</b><br>
              Agustus 2019 – Sekarang
            </p>
            <p>
              <b>Hasil:</b><br>
              Meningkatkan produktivitas pekerja (50-60% dari jam kerja)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="AB-Sistem-Kontrol">
      <div id="App-inner" class="AB-inner">
        <div class="AB-App-up">
          <h1 class="aos" data-aos="fade-right" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
            <span class="green"><b>Sistem Kontrol dan Monitoring Greenhouse</b></span>
          </h1>
          <h3><span class="green">Durasi : Juni 2020 – Februari 2021</span> </h3>
        </div>
        <div class="AB-Layanan-det">
        <div class="img">
          <img class="AB-App-down aos" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/ImplementasiKontrol1.jpg" alt="" />
        </div>
        <div class="img">
          <img class="AB-App-down aos" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/ImplementasiKontrol2.png" alt="" />
        </div>
        </div>  
        <p>
          PT Kharisma Agri Inovasi bekerja sama dengan PT Mitra
          Inovasi Agroplasa untuk mengimplementasikan teknologi
          pada 19 greenhouse. Instalasi CH-I atau sistem monitoring
          dilakukan pada 13 greenhouse. Sedangkan instalasi CH-I dan
          CH-C pada 6 smart greenhouse memudahkan pengguna
          dalam melakukan pemantauan (monitoring), notifikasi, dan
          otomatisasi alat untuk mengendalikan kondisi lingkungan
          mikro di dalam greenhouse secara realtime. Keenam smart
          greenhouse tersebut diimplementasikan di Politeknik
          Pembangunan Pertanian (POLBANGTAN) Bogor yang telah
          diresmikan penggunaanya pada Januari 2021 oleh Menteri
          Pertanian RI.  
        </p>     
      </div>
    </div>
  </div>

  <div class="section">
      <div class="AB-inner">
        <h1 class="aos" data-aos="fade-right" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
            <span class="green"><b>MITRA KAMI</b></span>
        </h1>
        <div class="MitraLogo">
        <div class="AB-mitra-1">
          <img class="aos" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra1.jpg" alt="" />
        </div>
        <div class="AB-mitra-2">
          <img class="aos" data-aos="flip-left" data-aos-delay="600" data-aos-duration="1000"  data-aos-once="true" src="../../assets/mitra2.jpg" alt="" />
        </div>
        <div class="AB-mitra-3">
          <img class="aos" data-aos="flip-left" data-aos-delay="700" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra3.jpg" alt="" />
        </div>
        <div class="AB-mitra-4">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra4.png" alt="" />
        </div>
        <div class="AB-mitra-5">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra5.png" alt="" />
        </div>
        <div class="AB-mitra-6">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra6.jpg" alt="" />
        </div>
        <div class="AB-mitra-7">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra7.jpg" alt="" />
        </div>
        <div class="AB-mitra-8">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra8.png" alt="" />
        </div>
        <div class="AB-mitra-9">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra9.png" alt="" />
        </div>
        <div class="AB-mitra-10">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra10.png" alt="" />
        </div>
        <div class="AB-mitra-11">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra11.png" alt="" />
        </div>
        <div class="AB-mitra-12">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra12.png" alt="" />
        </div>
        <div class="AB-mitra-13">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra13.jpg" alt="" />
        </div>
        <div class="AB-mitra-14">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra14.jpg" alt="" />
        </div>
        <div class="AB-mitra-15">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra15.png" alt="" />
        </div>
        <div class="AB-mitra-16">
          <img class="aos" data-aos="flip-left" data-aos-delay="800" data-aos-duration="1000" data-aos-once="true" src="../../assets/mitra16.png" alt="" />
        </div>
      </div>
    </div>  
  </div>

  <div class="section">
      <div class="StrukturPT">
          <h1 class="aos" data-aos="fade-right" data-aos-delay="400" data-aos-duration="600" data-aos-once="true">
            <span class="green"><b>STRUKTUR PERUSAHAAN</b></span>
          </h1>
        <div class="StrukturPT-image">
        <div class="img">
          <img src="../../assets/strukturPT.png" />
        </div>
        </div>  
      </div>
  </div>

    <div class="AB-foot">
      <img src="../../assets/Path2.png" alt="" />
      <div class="AB-inner">
        <div class="home-footer-left">
          <img src="../../assets/logobw.png" alt="" />
        </div>
        <div class="home-footer-right">
          <div class="footer-up">
            <p>Pabuaran Sawah RT.003/004,</p>
            <p>Cibanteng, Ciampea, Bogor, Jawa Barat, 16680</p>
          </div>
          <div class="footer-down">
            <div class="ig">
              <img src="../../assets/ig.png" alt="" />
              <p>crophero</p>
            </div>
            <div class="em">
              <img src="../../assets/emai.png" alt="" />
              <p>admin@kharismacrop.com</p>
            </div>
            <div class="br">
              <img src="../../assets/brow.png" alt="" />
              <p>kharismacrop.com</p>
            </div>
            <div class="tp">
              <img src="../../assets/telp.png" alt="" />
              <p>(+62) 812 9770 6625</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  methods: {
    playStore() {
      window.location = 'https://play.google.com/store/apps/details?id=com.march_2_send_push_notifications';
    }
  },
  mounted() {
    const aosAnimation = document.querySelectorAll(".aos");
    var observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("aos-animate");
        } else {
          entry.target.classList.remove("aos-animate");
        }
      });
    });
    aosAnimation.forEach((aosObject) => {
      observer.observe(aosObject);
    });
  },
};
</script>

<style>

.wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100px; 
    /* border: 1px solid black; */
}

#slide {
    position: absolute;
    left: -100px;
    width: 100px;
    height: 100px;
    background: blue;
    transition: 1s;
}

.wrapper:hover #slide {
    transition: 1s;
    left: 0;
}

.MileYear {
  width: 30%;
  float: left;
  padding: 100px 0;
  /* border: 1px solid whitesmoke; */
  background-image: white;
  /* margin-bottom: 10%; */
}


.Brand {
  width: 70%;
  float: left;
  padding: 30px;
  border: 1px solid whitesmoke;
  background-image: white;
  margin-bottom: 10%;
}

.Brand .AB-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Brand .pr-img-title {
  width: 100%;
  max-width: 30vh;
  margin: 0 auto;
  margin-bottom: 2em;
}

.Nilai {
  width: 30%;
  float: left;
  padding: 15px;
  border: 1px solid whitesmoke;
  background-color: darkgreen;
  display: grid;
  grid-template-columns: 20vh auto;
  grid-template-rows: auto auto auto auto;
  grid-gap: 0.2em;
}

.MitraLogo {
  /* width: 100%;
  float: left; */
  padding: 15px;
   /* border: 1px solid whitesmoke; */
  background-color: white;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  grid-gap: 1em;
  align-content: center;
  padding: 8%;
}

.MitraLogo img {
  width: 100%;
  max-width: 15vh;
  height: auto;
}

.StrukturPT {
  /* width: 100%;
  float: left; */
  padding: 15px;
   /* border: 1px solid whitesmoke; */
  background-color: white;
  align-content: center;
  padding-top: 5%;
  padding-left: 5%;
  background-color: rgb(245, 245, 245);
}

.StrukturPT-image {
  /* width: 100%;
  float: left; */
   /* border: 1px solid whitesmoke; */
  background-color: white;
  align-content: center;
  padding-top: 5%;
  padding-left: 15%;
  background-color: rgb(245, 245, 245);
}

.StrukturPT img {
  width: auto;
  max-width: 150vh;
  height: auto;
}

.MileText {
  width: 70%;
  float: right;
  padding: 40px;
  border: 1px solid whitesmoke;
  background-color: white;
  /* display: grid; */
  /* grid-template-columns: 20vh auto;
  grid-template-rows: auto auto auto auto; */
  grid-gap: 0.2em;
}

.Nilai .pr-img-title {
  width: 100%;
  float: right;
  padding: 15px;
  border: 1px solid whitesmoke;
  background-color: green;
}

.Nilai img {
  width: 100%;
  float: right;
  max-width: 15vh;
  padding: 12px;
  background-image: url(../../assets/bgproposisi1.png);
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: auto 20vh;
}

.green {
  color: #036b3a;
}

.red {
  color: #c00000;
}

li {
  font-size: 1.5rem;
}

h4  { 
  font-size: 250%;
  font-weight: 200;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  /* margin-bottom: 2em; */

}

h5  { 
  display: inline-block;
  border-radius: 20px;
  font-size: 250%;
  font-weight: 200;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  background-color: darkgreen;
  color: white;
  padding: 0.5em 0.2em 0.8em 0.5em;
  text-align: center;
  padding: 70px 0;
  /* margin-bottom: 2em; */
}

h6  { 
  display: inline-block;
  border-radius: 20px;
  font-size: 600%;
  font-weight: 200;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  background-color: darkgreen;
  color: white;
  padding: 0.5em 0.2em 0.8em 0.5em;
  /* margin-bottom: 2em; */
}

h7 {
  font-size: 400%;
  font-weight: 200;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  color: gold;
  font-style: bold;
}

h3 {
  padding: 30px 0;
  color: white;
}

p {
  font-size: 1.5rem;
  text-align: justify;
}

#AB {
  width: 100%;
  padding-top: 7.75vh;
  /* scroll-snap-type: mandatory; */
  overflow-y: scroll;
  height: 100vh;
  /* scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scroll-padding-top: 7.75vh; */
}

/* .section {
  scroll-snap-align: start;
} */

.AB-inner {
  width: 90%;
  max-width: 2000px;
  margin: 0 auto;
}

.AB-visi {
  width: 100%;
  height: 100vh;
  /* padding: 15vh 0 0 0; */
  /* border: green 1px solid; */
}

#visi-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  height: 100%;
  align-items: center;
  grid-gap: 1em;
}

.AB-visi-left {
  width: 100%;
  border-right: 2px solid #006837;
}

.AB-visi-left img {
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.AB-visi-right {
  width: 100%;
  height: 50vh;
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AB-visi-right p {
  width: 100%;
  font-size: 1.5rem;
}

.AB-visi-right h2 {
  margin-top: 1em;
  font-size: 2.25rem;
  color: black;
  margin-bottom: 0.25em;
}

.AB-Sistem-Kontrol {
  width: 100%;
  height: 100%;
  background: rgb(231, 230, 230);
  padding: 3em 0 3em 0;
}

.AB-Sistem-Kontrol img {
  width: 100%;
  max-width: 50vh;
  padding: 12px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}

.AB-App {
  width: 100%;
  height: 100%;
  background: #006837;
  padding: 3em 0 3em 0;
}

#App-inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.AB-App-up {
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 0 0em;
  height: fit-content;
}

.AB-App-up h1 {
  font-weight: 400;
}

.AB-App-up-aval {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AB-App-up-aval h3 {
  font-weight: 400;
}

.AB-App-up-aval img {
  width: 15vh;
  margin-left: 0.5em;
  cursor: pointer;
}

.AB-App-down {
  width: 100%;
}

.AB-foot {
  margin-top: 5em;
  width: 100%;
}

.AB-foot img {
  width: 100%;
  object-fit: contain;
}

.AB-foot .AB-inner {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -40px;
}

.home-footer-left {
  width: 50%;
}

.home-footer-left img {
  width: 100%;
  max-width: 300px;
  object-fit: cover;
}

.home-footer-right {
  width: 50%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  color: #006838;
}

.home-footer-right .footer-down {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 0.35em;
  margin-top: 0.75em;
}

.home-footer-right .footer-down div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home-footer-right .footer-down div img {
  width: 20px;
  object-fit: contain;
  margin-right: 0.5em;
}

.AB-info {
  width: 100vw;
  height: 50vh;
  margin: 0 auto;
  margin-top: 5em;
  margin-bottom: 5em;
}

.AB-info .AB-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr auto;
  grid-gap: 0em;
  padding-top: 20vh;
}

.AB-info-1,
.AB-info-2,
.AB-info-3,
.AB-info-4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AB-info img {
  width: 100%;
  max-width: 10vh;
  object-fit: contain;
}

.AB-info h2 {
  font-size: 1.5rem;
  font-weight: 200;
  width: 100%;
  text-align: center;
  margin-top: 1em;
}
.AB-Layanan {
  width: 100%;
  padding: 4em 0 20vh 0;
  background: linear-gradient(180deg,
      rgba(244, 245, 246, 1) 0%,
      rgba(238, 239, 240, 1) 29%,
      rgba(221, 222, 223, 1) 72%,
      rgba(208, 208, 209, 1) 100%);
}

.AB-Layanan-1 img {
  width: 100%;
  float: left;
  max-width: 82vh;
  padding: 12px;
  border-radius: 20px;
  }

  .AB-Layanan-2 img {
  width: 100%;
  float: right;
  max-width: 100vh;
  padding: 12px;
  border-radius: 20px;
  }

.AB-Layanan-1{
    order: 1;
  }
  .AB-Layanan-2{
    order: 2;
  }
  .AB-Layanan-3{
    order: 3;
  }
  .AB-layanan-txt1{
    order: 3;
    text-align: justify;
    margin-left: 4%;
  }
  .AB-layanan-txt2{
    order: 4;
    text-align: justify;
    margin-left: 6%;
  }
  .AB-layanan-txt3{
    order: 3;
  }

  .AB-Layanan .layanan-pr-a {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.AB-Layanan-det {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 2em;
}

.Milestone {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  grid-gap: 2em;
}

.AB-Prod {
  width: 100%;
  padding: 4em 0 20vh 0;
  background: linear-gradient(180deg,
      rgba(244, 245, 246, 1) 0%,
      rgba(238, 239, 240, 1) 29%,
      rgba(221, 222, 223, 1) 72%,
      rgba(208, 208, 209, 1) 100%);
}

.AB-Prod .AB-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AB-Prod .pr-txt-title {
  font-weight: 700;
  font-size: 2rem;
  color: #006837;
  width: 100%;
  text-align: center;
  margin-bottom: 0.75em;
}

.AB-Prod .pr-img-title {
  width: 100%;
  max-width: 20vh;
  margin: 0 auto;
  margin-bottom: 2em;
}

.AB-Prod-det {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: fit-content fit-content;
  grid-gap: 2em;
}

.AB-Prod-det h1 {
  text-align: left;
}

.AB-Prod-det div {
  width: 100%;
}

.AB-Prod-det div img {
  width: 85%;
  object-fit: contain;
}

.AB-Prod .prod-pr-a {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.AB-Prod-det .pr-a {
  width: 125px;
  object-fit: contain;
}

.AB-Prod-det h1,
.AB-Prod-det h2 {
  font-weight: 400;
}

.AB-Prod-det p {
  margin-top: 1em;
  font-size: 1.15rem;
  text-align: justify;
}

.AB-Prod-1,
.AB-Prod-2,
.AB-Prod-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

/* .AB-Prod-txt1h1,
.AB-Prod-txt2 h1,
.AB-Prod-txt3 h1 {
} */

@media screen and (max-width: 1000px){
  .AB-visi-right p {
    width: 100%;
    font-size: 1.25rem;
  }

  .AB-visi-right h2 {
    margin-top: 1em;
    font-size: 2.0rem;
    color: black;
    margin-bottom: 0.25em;
  }
/* .AB-Prod .pr-txt-title {
  font-size: 1.75rem;
} */
.AB-Prod-det p {
  font-size: 1rem;
}
.AB-Prod-det h1 {
  font-size: 1.5rem;
}
.AB-Prod-det h2 {
  font-size: 1.25rem;
}
}
@media screen and (max-width: 800px){
  .AB-Prod-det {
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 2em;
  }
  .AB-Prod-1{
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .AB-Prod-2{
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .AB-Prod-3{
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .AB-Prod-1,
  .AB-Prod-2,
  .AB-Prod-3 {
    justify-content: flex-start;
  }
  .prod-pr-a img{
    max-width: 100px;
  }
}
@media screen and (max-width: 550px){
  .AB-App-up {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .AB-App-up h1 {
    margin-bottom: 0.5em;
  }
  .AB-Prod-det {
    display: flex;
    flex-direction: column;
  }
  .AB-Prod-1{
    order: 3;
  }
  .AB-Prod-2{
    order: 1;
  }
  .AB-Prod-3{
    order: 5;
  }
  .AB-prod-txt1{
    order: 2;
  }
  .AB-prod-txt2{
    order: 4;
  }
  .AB-prod-txt3{
    order: 5;
  }
  .AB-Prod-1,
  .AB-Prod-2,
  .AB-Prod-3 {
    justify-content: flex-start;
  }
  .prod-pr-a img{
    max-width: 100px;
  }
  .AB-Prod-det p {
  font-size: 1rem;
}
.AB-Prod-det h1 {
  font-size: 2rem;
}
.AB-Prod-det h2 {
  font-size: 1.5rem;
}
}
@media screen and (max-width: 950px){
  .AB-App {
    width: 100%;
    height: fit-content;
    background: #006837;
    padding: 3em 0 3em 0;
  }
  .AB-info {
    height: fit-content;
  }
  
.AB-info-1,
.AB-info-2,
.AB-info-3,
.AB-info-4 {
  transform: scale(0.8);
}
}

@media screen and (max-width: 800px){
  .AB-mitra {
    width: 100%;
    height: fit-content;
    background: #006837;
    padding: 3em 0 3em 0;
  }
  .AB-mitraLogo {
    /* height: fit-content; */
    display: grid;
    grid-gap: 0.2em;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }
  

/* .AB-mitra-1,
.AB-mitra-2,
.AB-mitra-3,
.AB-mitra-4,
.AB-mitra-5,
.AB-mitra-6,
.AB-mitra-7,
.AB-mitra-8,
.AB-mitra-9,
.AB-mitra-10,
.AB-mitra-11,
.AB-mitra-12,
.AB-mitra-13,
.AB-mitra-14,
.AB-mitra-15,
.AB-mitra-16,{
  transform: scale(1);
} */
}

@media screen and (max-width: 700px){
  .AB-visi{
    padding: 10% 0 0 0;
  }

  .AB-info {
    margin-top: -3em;
  }
  .AB-info .AB-inner {
    display: grid;
    grid-template-columns: none;
    grid-gap: 2em;
  }
  
.AB-info-1,
.AB-info-2,
.AB-info-3,
.AB-info-4 {
  transform: scale(0.8);
}
  #visi-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .AB-visi-left {
    width: 100%;
    border-right: none;
  }
    .AB-visi-left img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
  }
  .AB-visi-right {
    width: 100%;
    height: fit-content;
    color: #006837;
    padding-left: 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AB-visi-right p {
    width: 100%;
    font-size: 1rem;
    text-align: center;
  }

  .AB-visi-right h2 {
    margin-top: 1em;
    font-size: 1.5rem;
    color: black;
    margin-bottom: 0.25em;
  }
  .AB-App-up h1 {
   font-size: 1.5rem;
  }
  .AB-App-up-aval h3 {
    font-size: 1rem;
  }

  .AB-App-up-aval img {
    width: 15vh;
    margin-left: 0.5em;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1000px) {
  .AB-foot{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .AB-foot .AB-inner{
    margin-top: 0.75em; 
  }
  .home-footer-left {
    width: 50%;
  }
  .home-footer-right .footer-down {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
  }
  .home-footer-right p {
    font-size: 0.75rem;
  }
}
</style>
